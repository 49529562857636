import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva, type VariantProps } from 'class-variance-authority';
import RouterLink from 'next/link';
import { cn } from '@/util/styleUtils';
import { Loader2 } from 'lucide-react';

const buttonVariants = cva(
    'font-bold inline-flex items-center justify-center rounded-full text-sm ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 whitespace-nowrap',
    {
        variants: {
            size: {
                default: 'h-10 px-4 py-2',
                sm: 'h-9 px-3',
                lg: 'h-11 px-8',
                xl: 'h-14 px-8 text-lg',
                icon: 'h-10 w-10',
                'icon-sm': 'h-7 w-7',
            },
            minWidth: { default: '', sm: 'min-w-[6rem]', md: 'min-w-[8rem]', xs: 'min-w-[5rem]' },
            color: {
                destructive: '',
                primary: '',
                default: '',
            },
            variant: {
                default: 'bg-primary text-primary-foreground hover:bg-primary/90',
                orange: 'bg-orange hover:bg-orange/90',
                'outlined-orange':
                    'border border-orange bg-transparent hover:bg-orange/75 hover:text-white text-foreground',
                destructive: 'bg-destructive text-destructive-foreground hover:bg-destructive/90',
                outlined:
                    'border border-primary bg-background hover:bg-accent hover:text-accent-foreground text-foreground',
                'alert-default':
                    'border border-purple-300 bg-background hover:bg-accent hover:text-accent-foreground text-foreground',
                'alert-secondary':
                    'border border-blue-300 bg-background hover:bg-accent hover:text-accent-foreground text-foreground',
                secondary: 'bg-secondary text-secondary-foreground hover:bg-secondary/80',
                ghost: 'hover:bg-accent hover:text-accent-foreground text-primary',
                link: 'text-primary underline-offset-4 hover:underline',
            },
        },
        defaultVariants: {
            variant: 'default',
            size: 'default',
            color: 'default',
            minWidth: 'default',
        },
        compoundVariants: [
            // Added link here as it's own compound variant to override size styles, since compoundVariants are added after base variants
            { variant: 'link', className: 'px-0 py-0 h-auto' },
            {
                variant: 'outlined',
                color: 'default',
                className: 'border bg-background hover:bg-accent hover:text-accent-foreground',
            },
            {
                variant: 'outlined',
                color: 'destructive',
                className:
                    'border-destructive bg-transparent text-destructive hover:bg-destructive hover:text-destructive-foreground',
            },
            {
                variant: 'outlined',
                color: 'primary',
                className: 'border-primary bg-transparent text-primary hover:bg-primary hover:text-primary-foreground',
            },
            { variant: 'orange', color: 'primary', className: 'text-green-dark' },
            { variant: 'orange', color: 'default', className: 'text-white' },
        ],
    }
);

export interface ButtonProps
    extends Omit<React.ButtonHTMLAttributes<HTMLButtonElement>, 'color'>,
        VariantProps<typeof buttonVariants> {
    asChild?: boolean;
    isLoading?: boolean;
    fullWidth?: boolean;
    mobileFullWidth?: boolean;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
    (
        {
            className,
            mobileFullWidth = false,
            color,
            type = 'button',
            fullWidth,
            isLoading,
            children,
            variant,
            size,
            asChild = false,
            minWidth,
            ...props
        },
        ref
    ) => {
        const Comp = asChild ? Slot : 'button';
        return (
            <Comp
                className={cn(buttonVariants({ variant, size, className, color, minWidth }), {
                    'w-full': fullWidth,
                    'w-full sm:w-auto': mobileFullWidth && !fullWidth,
                })}
                ref={ref}
                data-button={'button'}
                type={type}
                {...props}
            >
                {isLoading && <Loader2 className="-ml-1 mr-2 h-4 w-4 animate-spin" />}
                {children}
            </Comp>
        );
    }
);
Button.displayName = 'Button';

export interface LinkProps
    extends Omit<React.AnchorHTMLAttributes<HTMLAnchorElement>, 'color'>,
        VariantProps<typeof buttonVariants> {
    asChild?: boolean;
    href: string;
    external?: boolean;
    disabled?: boolean;
    fullWidth?: boolean;
    mobileFullWidth?: boolean;
}

const LinkButton = React.forwardRef<HTMLAnchorElement, LinkProps>(
    (
        {
            href,
            mobileFullWidth = false,
            fullWidth = false,
            disabled,
            external,
            color,
            className,
            variant,
            size,
            minWidth,
            asChild = false,
            ...props
        },
        ref
    ) => {
        if (!external) {
            return (
                <RouterLink
                    href={href}
                    className={cn(
                        buttonVariants({ variant, size, className, color, minWidth }),
                        {
                            'pointer-events-none opacity-50': disabled,
                            'w-full': fullWidth,
                            'w-full sm:w-auto': mobileFullWidth && !fullWidth,
                        },

                        className
                    )}
                    ref={ref}
                    data-button={'link'}
                    {...props}
                />
            );
        }
        const Comp = asChild ? Slot : 'a';
        return <Comp href={href} className={cn(buttonVariants({ variant, size, className }))} ref={ref} {...props} />;
    }
);
LinkButton.displayName = 'LinkButton';

export { LinkButton, Button, buttonVariants };
